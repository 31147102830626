@import "assets/styles/theme/theme";
.title {
    font-family: 'inter500';
    font-size: 24px;
    margin-top: 24px;

    @include ellipsis;
}

.secondary-title {
    font-family: 'inter500';
    font-size: 16px;
    margin-top: 24px;
    line-height: 24px;
}

.property-value {
    @include small-text-label;
    @include ellipsis;
}
