@import "assets/styles/theme/theme";
.tabs-container {
    margin-top: 27px;
    justify-content: space-between;
}

.controls-container {
    > div:first-child {
        background-color: #fff;
        border-color: #a2a9b1 !important;
    }

    > div:not(:last-child) {
        margin-right: 48px;
    }
}

.tab-root {
    width: 230px !important;
}

.table-row-controls-container {
    white-space: nowrap;

    .rerun-button {
        cursor: pointer;
        user-select: none;

        &.disabled {
            cursor: default;
            color: $muted;

            .icon {
                color: $muted;
            }
        }

        &:hover:not(.disabled) {
            color: $highlight-color;

            .icon {
                color: $highlight-color;
            }
        }

        .icon {
            color: rgba(101, 111, 123, 0.98);
            margin-right: 10px;
        }
    }
}

.tooltip {
    font-size: 12px !important;
    background-color: $neutral-900 !important;
    padding: 8px !important;
    max-width: 500px !important;
}

.label {
    display: inline-block;
    height: 28px;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;

    &.running {
        border: 1px solid $blue-300;
        background-color: $blue-100;
    }

    &.succeeded {
        background-color: $green-100;
        color: $green-600;
    }

    &.failed {
        background-color: $negative-lighter;
    }

    &.warning {
        background-color: $warning-lighter;
    }
}
