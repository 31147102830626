@import "assets/styles/theme/theme";
.root {
    display: flex !important;
    width: 48px !important;
    height: 24px !important;
    padding: 0 !important;

    .switch-base {
        padding: 4px 5px !important;
        color: $neutral-600 !important;

        .thumb {
            width: 16px !important;
            height: 16px !important;
            box-shadow: none !important;
        }

        &.checked {
            transform: translateX(22px) !important;
            color: $highlight-color !important;

            +.track {
                background-color: $lighter !important;
                border-color: $highlight-color;
            }
        }

        &.disabled {
            opacity: 0.5;
            color: $neutral-600 !important;

            +.track {
                border-color: $muted !important;
                background-color: $neutral-100 !important;
            }
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0) !important;
        }
    }

    .track {
        border: 1px solid $muted;
        border-radius: 18px !important;
        opacity: 1 !important;
        background-color: $neutral-100 !important;
    }
}
