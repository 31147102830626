@import "assets/styles/theme/theme";
$base-color: $neutral-200;
$shine-color: rgba(#ffffff, 0.5);
$animation-duration: 2s;

@keyframes shine-lines {
    0% {
        background-position: 100%;
    }
    40%,
    100% {
        background-position: -100%;
    }
}

.loading-animation {
    background-image: linear-gradient(90deg, $base-color 0%, $shine-color 25%, $base-color 50%);
    background-size: 200%;
    animation: shine-lines $animation-duration infinite linear;
}
