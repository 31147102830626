@import "assets/styles/theme/theme";
.input {
    display: inline-block !important;

    input {
        width: 220px !important;
    }
}

.message {
    font-size: 12px;
    margin-top: 8px;
}

.tooltip {
    font-size: 12px !important;
    background-color: $neutral-900 !important;
    padding: 8px !important;
}
