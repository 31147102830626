@import "assets/styles/theme/theme";
.arrow {
    position: relative;
    top: 1px;
    left: 10px;
    color: #b2b7bd;
    font-size: 16px;

    &.disabled {
        color: $neutral-600;
    }
}

.divider {
    height: 1px;
    background-color: $muted;
    margin-top: 4px;
}
