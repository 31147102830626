@import "assets/styles/theme/theme";
.title {
    font-family: 'inter500';
    margin: 20px 0 16px 0;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.more-button {
    background-color: #fff !important;
    color: $text-color !important;
    border: 1px solid #868e96 !important;
    border-radius: 2px !important;
    box-shadow: none !important;
    margin-top: 6px;

    &[disabled] {
        opacity: 0.5;
    }
}

.no-data-message {
    font-size: 14px;
    margin: 12px 0;
    color: #6a737c;
    height: 21px;
    line-height: 21px;
}

.spinner {
    top: 200px;
    left: calc(50% - 24px);
}

.column {
    margin-right: 24px;
}

.property-name {
    color: #6a737c;
    font-size: 14px;
    height: 21px;
    line-height: 21px;
}

.property-value {
    font-size: 18px;
    height: 27px;
    line-height: 27px;
}

.table-title {
    font-family: 'inter500';
    margin: 32px 0 8px 0;
    height: 24px;
    line-height: 24px;
}

.schema-row {
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid $neutral-300;

    &.header {
        font-family: 'inter500';
        font-size: 12px;
        color: #6a737c;
        height: 40px;
        line-height: 40px;
        user-select: none;

        .column-name,
        .column-type {
            cursor: pointer;
        }

        .sort-order-icon {
            font-size: 10px;
            margin-left: 5px;
            margin-bottom: 1px;
        }

        .muted-icon {
            font-size: 9px;
            margin-left: 5px;
            margin-bottom: 1px;
            color: #d0d5da;

            &:last-child {
                margin-left: 1px;
            }
        }
    }

    .column-name {
        flex: 1 1 auto;
        overflow: hidden;
        padding-right: 4px;
    }

    .column-type {
        flex: 0 0 80px;
    }

    .column-nullable {
        flex: 0 0 54px;

        .label {
            margin-left: 20px;
            font-size: 16px;
            color: rgba($text-color-alternate, 0.98);
        }
    }

    &:last-child {
        border-bottom: none;
    }
}
