@import "assets/styles/theme/theme";
.table-controls {
    justify-content: space-between;
    height: 36px;
    margin: 24px 0 16px 0;

    > div > div:not(:last-child) {
        margin-right: 12px;
    }
}

.filter-button {
    background-color: #fff !important;
    color: $text-color !important;
    border: 1px solid #d0d5da !important;
    box-shadow: none !important;

    &[disabled] {
        opacity: 0.5;
    }
}

.filter-icon {
    margin-right: 8px;
}

.status-container {
    .status-icon {
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 100px;
        margin-right: 12px;
    }

    &.succeeded .status-icon,
    &.started .status-icon {
        background-color: #25d089;
    }

    &.failed {
        color: #b82533;

        .status-icon {
            background-color: #d33c46;
        }
    }

    &.unreconciled {
        color: #000000;

        .status-icon {
            background-color: #333;
        }
    }

    &.cancelled .status-icon {
        background-color: $neutral-300;
    }

    &.empty .status-icon {
        background-color: #e98734;
    }
}

.card {
    @include card;

    margin-top: 20px;
    padding: 8px 24px 48px 24px;
}

.tooltip {
    font-size: 12px !important;
    background-color: $neutral-900 !important;
    max-height: 300px;
    overflow-y: auto;
}

.tooltip-title {
    font-family: 'inter500';
}

tr:hover .name {
    color: $highlight-color-dark;
}

.name {
    line-height: 29px;
}

.label {
    display: inline-block;
    height: 28px;
    margin-left: 16px;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;
    flex: 0 0 auto;

    &.ad-hoc-label {
        background-color: $neutral-200;
    }

    &.restarted-label {
        color: #fff;
        background-color: #25d089;
    }
}
