@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 20px;
    padding: 24px;

    &:nth-child(n+3) {
        margin-top: 16px;
    }
}

.title-container {
    justify-content: space-between;
}

.title {
    font-family: 'inter500';
    font-size: 24px;
    padding-bottom: 24px;
}

.buttons-container > *:not(:last-child) {
    margin-right: 12px;
}

.name-input input {
    width: 496px !important;
}

.warning {
    font-size: 12px;
    margin-top: 8px;
}

.field:not(:last-child) {
    margin-bottom: 24px;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.table-label {
    @include small-text-label;

    display: inline-block;
    font-family: 'inter500';
    margin-bottom: 8px;

    &:first-child {
        width: 256px;
    }
}

.platforms-error {
    color: $error-text-color;
    font-size: 12px;
    margin-bottom: 20px;
}

.next-button {
    width: 65px;

    .submitting-icon {
        margin-top: 5px;
        margin-left: -1px;
    }
}

.market-tooltip {
    margin-left: 4px;
}

.spinner-container {
    height: 365px;

    .spinner {
        top: 140px;
        left: calc(50% - 24px);
    }
}

.templates-container {
    margin-top: 16px;
    flex-wrap: wrap;
    gap: 20px;

    .template-container {
        cursor: pointer;
        border: 1px solid #d0d5da;
        border-radius: 2px;
        width: calc(33.33% - 14px);
        aspect-ratio: 1.45;

        .template-title-row {
            height: 50px;
            line-height: 50px;
            padding: 0 16px;
            justify-content: space-between;
            font-size: 12px;

            .template-icon {
                color: #d0d5da;
                font-size: 20px;
                margin-top: 14px;
            }
        }

        .template-thumbnail {
            height: 67%;
            object-fit: contain;
        }

        &.selected {
            border-color: $highlight-color;

            .template-icon {
                color: $highlight-color;
            }
        }
    }
}

.non-editable-field {
    @include small-text-label;
    @include ellipsis;
}
