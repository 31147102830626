@import "assets/styles/theme/theme";
.container {
    padding: 16px 24px 24px 24px;
    font-size: 14px;
    min-width: 200px;
    max-height: 300px;

    .group {
        background-color: #fff;

        .group-title {
            color: $dark-muted;
            margin: 4px 0;
        }

        .group-items-container {
            padding-left: 16px;

            .group-item {
                user-select: none;
                line-height: 32px;

                .check-icon {
                    color: $highlight-color-dark;
                    float: right;
                    margin-top: 8px;
                }
            }
        }
    }

    .group:not(.selected-group) .group-item:not(.selected-item) {
        cursor: pointer;

        &:hover {
            color: $highlight-color;
        }
    }

    .selected-group .group-item:not(.selected-item) {
        color: $dark-muted;
    }
}

// TODO add styles for disabled menu
