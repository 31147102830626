@import "assets/styles/theme/theme";
.datepicker-input {
    width: 190px;
    height: 36px;
    padding-right: 15px !important;
    color: $accent-color-dark !important;
    font-family: 'inter500' !important;
    font-size: 14px !important;
    pointer-events: none;

    fieldset {
        border: 1px solid $muted !important;
    }

    button {
        pointer-events: all;

        &:hover {
            background-color: #fff !important;
        }
    }

    input {
        height: auto !important;
        padding-left: 13px;
    }
}

.datepicker-input-disabled {
    opacity: 0.5;

    input {
        -webkit-text-fill-color: inherit !important;
    }
}

.icon {
    font-size: 16px;
    cursor: pointer;
}
