@import "assets/styles/theme/theme";
.error-page {
    flex: 1;
    align-items: center;

    .error {
        margin-top: 104px;
        margin-bottom: 8px;
        color: $dark-muted;
        font-size: 18px;
    }

    .title {
        color: $light-muted;
        margin: 0;
        font-size: 60px;
        font-family: 'inter700';
    }

    .description {
        margin-top: 16px;
        margin-bottom: 56px;

        p {
            color: $dark;
            font-size: 18px;
            text-align: center;
            margin: 0;

            &:not(:first-child) {
                margin-top: 8px;
            }
        }
    }

    .need-help {
        margin-top: 32px;
        font-size: 14px;
        color: $text-color;

        a {
            color: $highlight-color-dark;
        }
    }
}
