@import "assets/styles/theme/theme";
.titleContainer {
    justify-content: space-between;
}

.title {
    font-family: 'inter500';
    height: 36px;
    line-height: 36px;
    font-size: 20px;
}
