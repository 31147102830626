@import "assets/styles/theme/theme";
.label {
    font-size: 14px;
    padding: 16px 0 8px 0;
    font-family: 'inter500';
}

.option {
    width: 100px;
}

.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;

        &:first-child {
            margin-right: 12px;
        }
    }
}
