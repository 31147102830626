@import "assets/styles/theme/theme";
.container {
    border: 1px solid $muted;
    border-radius: 4px;
    padding: 0 16px 0 12px;
    margin: 12px 0;

    &:first-child {
        margin: 0;
    }
}

.item-root {
    margin-left: 12px !important;
}

.delete-icon-container {
    position: absolute;
    right: 12px;
    bottom: 24px;

    .delete-icon {
        font-size: 16px;
        color: rgba(101, 111, 123, 0.98);
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .delete-icon {
            color: $muted;
        }
    }
}

.error-icon {
    position: absolute;
    right: 46px;
    top: 16px;
    color: $error-border-color;
}

.accordion-details {
    margin-bottom: 0 !important;
}

.accordion-label {
    width: 100%;
}

.bottom-container {
    position: relative;
}

.field {
    margin-bottom: 24px;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}
