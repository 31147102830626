@import "assets/styles/theme/theme";
.field {
    margin-right: 24px;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.target-container {
    border: 1px solid #d3d6d9;
    border-radius: 4px;
    padding: 12px 24px 24px 12px;
    margin-bottom: 12px;
    width: 740px;
}

.target-delete-icon-container {
    margin-top: 33px;

    .delete-icon {
        font-size: 16px;
        color: rgba(101, 111, 123, 0.98);
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .delete-icon {
            color: $muted;
        }
    }
}
