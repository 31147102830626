@import "assets/styles/theme/theme";
.radio-root:hover {
    background-color: transparent !important;
}

.radio-checked-icon {
    width: 20px !important;
    height: 20px !important;
}

.radio-unchecked-icon {
    width: 18px !important;
    height: 18px !important;
    color: transparent;
    border: 1px solid $muted;
    border-radius: 100px;
}

.radio-checked-icon {
    color: $highlight-color;
}

.radio-label-root {
    font-size: 14px !important;
    color: $text-color !important;
    font-family: 'inter400' !important;
}
