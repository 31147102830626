@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 20px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
}

.title {
    font-family: 'inter500';
    font-size: 24px;
    padding-bottom: 24px;
}

.buttons-container > *:not(:last-child) {
    margin-right: 12px;
}

.field:not(:last-child) {
    margin-bottom: 24px;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.big-label {
    margin-top: 40px;
    font-size: 20px;
    padding-bottom: 16px;
}

.warning {
    font-size: 12px;
    margin-top: 8px;
}

.global-error {
    color: $error-text-color;
    font-size: 12px;
    margin-bottom: 20px;
}

.checkboxex-container {
    display: inline-block;
    padding: 4px;
}

.switch-container {
    margin-bottom: 24px;
}

.option {
    width: 100px;
}

.checkbox {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    width: 36px;
    margin-right: 8px;
    text-align: center;
    font-family: 'inter500';
    color: $text-color-alternate;
    border-radius: 2px;
    border: 1px solid $muted;
}

.checkbox-checked {
    background-color: $lighter;
    color: $highlight-color-dark;
}

.cron-controls {
    margin-bottom: 24px;

    &> div {
        margin-right: 16px;
    }
}

.credential-field {
    margin-top: 24px;
}

.offset-days-input input {
    width: 320px !important;
}

.delete-icon-container {
    position: absolute;
    right: 12px;
    bottom: 24px;

    .delete-icon {
        font-size: 16px;
        color: rgba(101, 111, 123, 0.98);
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .delete-icon {
            color: $muted;
        }
    }
}

.schedule-container {
    border: 1px solid $muted;
    border-radius: 4px;
    padding: 0 16px 0 12px;
    margin-bottom: 12px;
}

.schedule-details {
    margin-bottom: 0 !important;
}

.schedule-label {
    width: 100%;
}

.error-icon {
    position: absolute;
    right: 46px;
    top: 16px;
    color: $error-border-color;
}

.bottom-container {
    position: relative;
}

.source-select-option {
    padding: 0 12px !important;

    .source-option-name {
        display: inline-block;
        padding-top: 8px;
    }
}

.sources-list-test-label {
    display: inline-block;
    margin-left: 6px;
    text-transform: uppercase;
    background-color: $neutral-200;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
}

.schedules-warning,
.targets-warning {
    display: inline-block;
    font-size: 12px;
    padding: 8px;
    background-color: $warning-lighter;
    margin-bottom: 16px;
}

.targets-tooltip {
    margin-left: 5px;
}

.targets-label {
    margin-bottom: 8px;
}

.source-configuration-skeleton-rows {
    margin-bottom: 24px;
}

.error {
    margin-bottom: 12px;
}
