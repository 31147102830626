@import "assets/styles/theme/theme";
.search-input {
    margin-left: -28px;
    padding-left: 40px !important;
}

.search-icon {
    z-index: 1;
    margin: 9px 0 9px 12px;
    color: $neutral-600;
}
