@import "assets/styles/theme/theme";
.controls {
    justify-content: space-between;
    margin-top: 27px;
}

.table-controls > *:not(:last-child) {
    margin-right: 12px;
}

tr:hover .name {
    color: $highlight-color-dark;
}

.description {
    margin-top: 24px;
    font-size: 12px;
}
