@import "assets/styles/theme/theme";
.mode-label {
    @include small-text-label;

    margin-bottom: 8px;
}

.mode-switch {
    display: inline-block;
    border-radius: 2px;
    border: 1px solid $muted;
    padding: 4px;
}

.mode-switch-container {
    margin-bottom: 24px;
}

.mode-option {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    width: 100px;
    text-align: center;
    font-family: 'inter500';
    color: $text-color-alternate;
}

.selected-mode-option {
    background-color: $lighter;
    color: $highlight-color-dark;
}

.add-column-button-container {
    margin-top: 24px;
}

.table-label {
    @include small-text-label;

    display: inline-block;
    font-family: 'inter500';
    margin-bottom: 8px;

    &:first-child {
        width: 332px;
    }
}

.schema-file-name {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding-right: 16px;
    border-radius: 2px;
    background-color: #f6f8fa;
    margin-bottom: 8px;
    max-width: 100%;

    @include ellipsis;

    svg {
        color: $accent-color-dark;
        margin: 0 10px 0 18px;
    }

    span {
        font-family: 'inter500';
        font-size: 14px;
        color: $highlight-color-dark;
    }
}

.upload {
    height: 36px;
    display: inline-block;
    line-height: 36px;
    padding: 0 12px;
    border: 1px solid #aeb6be;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;

    .upload-icon {
        color: $neutral-600;
        margin-right: 8px;
        font-size: 16px;
    }

    &:hover {
        background-color: #aeb6be;
        color: #fff;

        .upload-icon {
            color: #fff;
        }
    }

    &:active {
        background-color: #626d7c;
    }

    &.upload-disabled {
        cursor: not-allowed;

        &:active {
            background-color: #aeb6be;
        }
    }
}

.avro-format {
    font-size: 12px;
    color: $accent-color-dark;
    margin-left: 12px;
}

.schema-error {
    color: $error-text-color;
    font-size: 12px;
    margin-left: 8px;
}

.schema-container {
    margin-bottom: 40px;
}
