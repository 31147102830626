@import "assets/styles/theme/theme";
.button {
    text-align: center;
    height: 36px;
    margin-top: 24px;
}

.items-count {
    text-align: center;
    color: $accent-color-dark;
    font-size: 12px;
    margin-top: 12px;
}
