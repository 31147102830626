@import "assets/styles/theme/theme";
// field common
.field:not(:last-child):not(:empty) {
    margin-bottom: 24px;
}

.field-label {
    font-family: 'inter500';
    font-size: 14px;
    margin-bottom: 8px;
}

.non-editable-field {
    font-size: 14px;
    word-break: break-all;
}

.field-message {
    font-size: 12px;
    margin-top: 8px;
    height: 15px;
}

// password, text fields
.text-input input {
    width: 440px;
}
.password-input input {
    width: 320px;
}

.text-overridable,
.password-overridable,
.checkbox-overridable,
.select-overridable,
.multi-select-overridable {
    margin-left: 3px;
}

.radio-overridable {
    // add styles if needed
}
