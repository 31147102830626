@import "assets/styles/theme/theme";
.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;

        &:first-child {
            margin-right: 12px;
        }
    }
}

.text {
    font-size: 14px;
    line-height: 21px;
    margin: 8px 0;
}

.field {
    margin-top: 12px;
}

.export-target-title {
    font-family: 'inter500';
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;

    @include ellipsis;
}

.loading {
    font-size: 14px;
}
