a {
    text-decoration: none;
    color: inherit;
}

.container-row {
    display: flex;
    flex-direction: row;
}

.container-column {
    display: flex;
    flex-direction: column;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.error-input input, .error-input textarea {
    background-color: $error-background-color !important;
    border-color: $error-border-color !important;
}

.form-error-message {
    color: $error-text-color;
    font-size: 12px;
    margin-top: 8px;
}

.btn-negative-transparent {
    @extend .btn-transparent;

    color: $error-text-color !important;

    &:hover,
    &:active {
        color: #fff !important;
    }
}

.plus-button {
    height: 36px;

    button {
        color: $text-color !important;
        font-family: 'inter400' !important;

        > svg {
            margin-right: 8px;
            font-size: 16px;
            color: rgba(101, 111, 123, 0.98);
        }

        &:hover {
            color: #fff !important;

            > svg {
                color: #fff !important;
            }
        }
    }
}
