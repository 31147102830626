@import "assets/styles/theme/theme";
.field-container {
    margin-top: 24px;

    &:first-child {
        margin: 0;
    }
}

.field {
    height: 36px;
}

.field-type-select-container {
    margin-left: 12px;
}

.field-nullable-container {
    margin-left: 3px;
}

.field-nullable-tooltip {
    margin-bottom: -1px;
    padding: 0 9px;
}

.field-name-input {
    width: 320px !important;
}

.field-delete-icon-container {
    line-height: 36px;
    margin-left: 40px;
}

.field-delete-icon {
    color: rgba(101, 111, 123, 0.98);
    cursor: pointer;
}
