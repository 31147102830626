@import "assets/styles/theme/theme";
.body {
    width: 750px;
}

.paper {
    border-radius: 4px;
    padding: 24px;
    overflow-x: hidden;
}
