@import "assets/styles/theme/theme";
.content-container {
    padding: 0 40px;
}

.template-name {
    font-family: 'inter500';
    margin: 10px 0 8px 0;
    font-size: 16px;
    line-height: 24px;
}

.credentials-title,
.authorizations-title {
    font-family: 'inter500';
    font-size: 16px;
    line-height: 24px;
    margin: 40px 0 8px 0;
}

.credentials-line,
.authorizations-line {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 8px;
}

.platform-row {
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid $neutral-300;

    &.header {
        font-family: 'inter500';
        font-size: 12px;
        color: #6a737c;
        height: 40px;
        line-height: 40px;
        user-select: none;

        .column-name,
        .column-market {
            cursor: pointer;
        }
    }

    .column-name {
        flex: 1 1 auto;
        overflow: hidden;
        padding-right: 4px;
    }

    .column-market {
        flex: 0 0 120px;
    }

    &:last-child {
        border-bottom: none;
    }
}

.spinner {
    top: calc(50% - 44px);
    left: calc(50% - 24px);
}
