@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 20px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
    height: 36px;
}

.title {
    font-family: 'inter500';
    font-size: 24px;

    @include ellipsis;
}

.owner-name {
    font-size: 20px;
    color: $accent-color-dark;

    @include ellipsis;
}

.label {
    display: inline-block;
    height: 28px;
    margin-top: 8px;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;
    background-color: $neutral-200;
}

.property-name {
    font-family: 'inter500';
    font-size: 16px;
    margin-top: 24px;
}

.property-value {
    font-size: 14px;
    margin-top: 8px;
}

.download {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin-top: 8px;
    padding-right: 16px;
    border-radius: 2px;
    background-color: #f6f8fa;
    max-width: 100%;

    svg {
        color: $accent-color-dark;
        margin: 0 10px 0 18px;
    }

    span {
        font-family: 'inter500';
        font-size: 14px;
        color: $highlight-color-dark;
        cursor: pointer;
    }
}

.icon {
    margin-left: 12px;
    font-size: 16px;
    color: rgba(101, 111, 123, 0.98);
}

.last-updated-title {
    font-family: 'inter500';
    font-size: 12px;
    margin-top: 18px;
}

.last-updated-value {
    font-size: 12px;
    margin-top: 8px;
}

.divider {
    @include divider(88px);
}
