// mui

.MuiTouchRipple-root {
    display: none !important;
}

// lightsaber

.ls-input {
    margin-bottom: 0 !important;

    input, textarea {
        font-family: 'inter400' !important;
        border: 1px solid $muted;
        box-shadow: none;
        color: $text-color;
        padding: 8px 12px;
        font-size: 14px;
        width: 440px;

        &:hover {
            background-color: $highlight-color-light;
            border-color: $muted;
        }

        &:focus, &:active {
            border-color: $highlight-color;
        }

        &[disabled] {
            background-color: $disabled-input-color;
            border-color: $muted;
        }
    }

    textarea {
        width: 100%;
        height: 103px; // 5 rows of text with used font-size and padding
        border-radius: 4px;
        resize: none;
    }

    &.password input {
        -webkit-text-security: disc;
    }
}

.ls-button button {
    white-space: nowrap;
    font-family: 'inter500' !important;

    &.btn-flat {
        font-family: 'inter400' !important;
        color: $text-color;
    }
}

// codemirror

.CodeMirror-selected {
    background-color: #ccc !important;
}

.CodeMirror-scroll {
    overflow-x: hidden !important;
    padding-bottom: 65px !important;
}

// notistack

.notistack-MuiContent-success {
    background-color: #25d089 !important;
    font-family: 'inter500' !important;
}

.notistack-MuiContent-error {
    color: $error-text-color !important;
    background-color: $error-background-color !important;
}
