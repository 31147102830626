@import "assets/styles/theme/theme";
tr:hover .name {
    color: $highlight-color-dark;
}

.name-container {
    white-space: nowrap;

    .name {
        line-height: 29px;
    }
}

.label {
    display: inline-block;
    height: 28px;
    margin-left: 16px;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;

    &.pending {
        border: 1px solid $blue-300;
        background-color: $blue-100;
    }

    &.superseded,
    &.reporting {
        background-color: $warning-lighter;
    }

    &.cancelled,
    &.test {
        background-color: $neutral-200;
    }

    &.validation-failed {
        background-color: $negative-lighter;
    }
}
