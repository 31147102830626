@import "assets/styles/theme/theme";
.datepicker-input {
    width: 135px;
    height: 36px;
    padding-right: 13px !important;
    color: $accent-color-dark !important;
    font-family: 'inter500' !important;
    font-size: 14px !important;

    fieldset {
        border: none !important;
    }

    button {
        pointer-events: all;
        padding: 0;

        &:hover {
            background-color: #fff !important;
        }
    }

    input {
        height: auto !important;
        padding-left: 0;
        text-align: center;

        user-select: none !important;
        cursor: pointer;
    }

    ::placeholder {
        opacity: 1 !important;
    }
}

.icon {
    font-size: 14px;
    cursor: pointer;
}

.text-input-disabled {
    pointer-events: none;
    opacity: 0.5;

    .icon {
        cursor: default;
    }
}
