@import "assets/styles/theme/theme";
.root {
    margin-top: 24px;
}

.block:not(:last-child) {
    margin-bottom: 24px;
}

.item:not(:last-child) {
    margin-bottom: 8px;
}
