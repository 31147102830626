@import "assets/styles/theme/theme";
.switch {
    display: inline-block;
    border-radius: 2px;
    border: 1px solid $muted;
    padding: 4px;
}

.option {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    padding: 0 5px;
    text-align: center;
    font-family: 'inter500';
    color: $text-color-alternate;
}

.selected-option {
    background-color: $lighter;
    color: $highlight-color-dark;
}

.disabled-option {
    background-color: $neutral-100;
    cursor: not-allowed;
}
