@import "assets/styles/theme/theme";
.table-cell-container {
    justify-content: space-between;
}

tr:hover .link {
    color: $highlight-color-dark;
}

.name {
    line-height: 36px;
}

.delete-icon-container {
    line-height: 36px;
    margin-left: 12px;

    .delete-icon {
        font-size: 16px;
        color: rgba(101, 111, 123, 0.98);
        cursor: pointer;
        position: relative;
        vertical-align: top;
        top: 50%;
        transform: translateY(-8px);
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .delete-icon {
            color: $muted;
        }
    }
}

.no-access-warning {
    text-align: center;
    padding-top: 40px;
}
