@import "assets/styles/theme/theme";
.title {
    font-family: 'inter700';
    font-size: 24px;
    padding-bottom: 26px;
}

.table-controls {
    justify-content: space-between;
    height: 36px;
    margin-bottom: 16px;
}

.buttons-container > *:not(:last-child) {
    margin-right: 12px;
}

.card {
    @include card;

    padding: 8px 24px 48px 24px;
}
