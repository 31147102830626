@import "assets/styles/theme/theme";
.field {
    margin-right: 16px;
}

.platform-container {
    margin-bottom: 12px;
    width: 740px;
}

.platform-delete-icon-container {
    margin-top: 8px;

    .delete-icon {
        font-size: 16px;
        color: rgba(101, 111, 123, 0.98);
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .delete-icon {
            color: $muted;
        }
    }
}

.warning-icon-container {
    margin: 10px 0 0 16px;

    .warning-icon {
        font-size: 18px;
        color: #c23e0a;
    }
}
