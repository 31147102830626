@import "assets/styles/theme/theme";
.field {
    margin-bottom: 24px;

    &:first-child {
        margin-top: 24px;
    }
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;

        &:first-child {
            margin-right: 12px;
        }
    }
}

.error-message {
    max-width: 440px;
}
