@import "assets/styles/theme/theme";
.container {
    justify-content: space-between;
    font-size: 14px;
    font-family: 'inter500';
    margin-bottom: 12px;

    .download {
        color: $highlight-color-dark;
        cursor: pointer;
        margin-right: 4px;
        user-select: none;

        .icon {
            margin-right: 4px;
        }
    }
}

.item {
    font-size: 14px !important;
    font-family: 'inter500' !important;

    &.focused-item {
        background-color: inherit !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
        }
    }
}

.paper {
    margin-top: 5px !important;
}
