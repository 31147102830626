@import "assets/styles/theme/theme";
.table-controls {
    margin: 27px 0 16px 0;
    justify-content: space-between;
    height: 44px;
}

.tab-root {
    width: 220px !important;
}

.new-button {
    width: 220px;
}

.no-access {
    text-align: center;
    padding-top: 48px;
}
