@import "assets/styles/theme/theme";
.sort-order-icon {
    font-size: 10px;
    margin-left: 5px;
    margin-bottom: 1px;
}

.muted-icon {
    font-size: 9px;
    margin-left: 5px;
    margin-bottom: 1px;
    color: #d0d5da;

    &:last-child {
        margin-left: 1px;
    }
}
