@import "assets/styles/theme/theme";
.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;

        &:first-child {
            margin-right: 12px;
        }
    }
}

.error-message {
    max-width: 440px;
}

.text-area {
    margin-top: 24px;
    width: 520px;
}
