@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 20px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
}

.title {
    font-family: 'inter500';
    font-size: 24px;
    padding-bottom: 24px;
}

.buttons-container > *:not(:last-child) {
    margin-right: 12px;
}

.field:not(:last-child) {
    margin-bottom: 24px;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.option {
    width: 100px;
}

.cron-controls {
    margin-top: 24px;

    > div {
        margin-right: 16px;
    }
}

.big-label {
    margin-top: 40px;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.table-label {
    @include small-text-label;

    display: inline-block;
    font-family: 'inter500';
    margin-bottom: 8px;

    &:first-child {
        width: 212px;
    }
}

.with-triggers {
    margin-top: 24px;
}

.export-target-title {
    font-family: 'inter500';
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;

    @include ellipsis;
}

.link-container {
    @include ellipsis;

    a {
        font-family: 'inter500';
        color: $link;
    }
}

.full-dataset-field {
    margin: 24px 0;
}

.warning {
    font-size: 12px;
    margin-top: 8px;
}

.additional-label {
    margin-left: 8px;
    color: $accent-color-dark;
    font-family: 'inter400';
}

.non-editable-field {
    @include small-text-label;
    @include ellipsis;
}

.logic-type-label {
    font-size: 14px;
    padding: 15px 0;
    font-family: 'inter500';
}

.label-tooltip {
    margin-left: 7px;
}

.trigger-logic-type-switch {
    margin-bottom: 21px;
}

.trigger-logic-type-option {
    width: 56px;
}
