@import "assets/styles/theme/theme";
.card {
    border-radius: 4px;
    padding: 24px;
    min-width: 533px;
}

.title {
    font-family: 'inter500';
    font-size: 20px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 3px;
}

.content {
    line-height: 21px;
    word-break: break-word;
}

.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;
    }
}
