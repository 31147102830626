$table-border-color: $neutral-300;
$secondary-column-max-width: 200px;

tr {
    height: 48px;
    border-bottom: 1px solid $table-border-color;
}

th {
    color: $neutral-600;
    font-family: 'inter500';
    font-weight: normal;
}

tbody tr:hover {
    background-color: $highlight-color-light;
}

th, td {
    text-align: left;
    padding: 8px 16px;
}

th:first-child,
td:first-child {
    padding-left: 8px;
}

th:last-child,
td:last-child {
    padding-right: 8px;
}

thead th {
    padding-bottom: 10px;
    vertical-align: bottom;
    white-space: nowrap;
}

th {
    cursor: pointer;
    user-select: none;
}

td {
    max-width: 0;
}

td.secondary-column-cell {
    max-width: $secondary-column-max-width;
}

tbody tr {
    cursor: pointer;
}
