@import "assets/styles/theme/theme";
.title {
    font-family: 'inter700';
    font-size: 24px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 16px;
}

.datasets-column,
.tables-column,
.schema-column {
    @include card;

    min-height: 536px;
    min-width: 0;
    background-color: #fff;
    margin: 24px 24px 0 0;

    &:last-child {
        margin-right: 0;
    }
}

.datasets-column {
    flex: 0 0 182px;
    padding: 0 16px;
}

.tables-column {
    flex: 1 1 292px;
    padding: 0 16px;
}

.schema-column {
    flex: 1 1 438px;
    padding: 0 24px;
}

.controls-container {
    justify-content: space-between;
}

.switch {
    border-radius: 2px;
    border: 1px solid $muted;
    padding: 4px;
    background-color: #fff;
}

.option {
    cursor: pointer;
    user-select: none;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    padding: 0 8px;
    text-align: center;
    font-family: 'inter500';
    color: $text-color-alternate;
}

.selected-option {
    color: $highlight-color-dark;
    background-color: $lighter;

    // TODO below there are colors from design, but they differ from similar already existing elements in the app
    // background-color: #cee6fd;
    // color: #005fbe;
}

.disabled-option {
    background-color: $neutral-100;
    cursor: not-allowed;
}

.search-tooltip {
    margin: 10px 0 0 12px;
}
