@import "assets/styles/theme/theme";
.container {
    height: 100%;
    margin: 32px 40px 16px 40px;
    justify-content: space-between;
}

.title {
    font-family: 'inter500';
    font-size: 20px;
    height: 30px;
    line-height: 30px;
}

.close-icon-container {
    position: absolute;
    top: 24px;
    right: 24px;

    .close-icon {
        font-size: 17px;
        color: $neutral-600;
        cursor: pointer;
    }
}

.buttons-container {
    justify-content: right;

    > div:not(:last-child) {
        margin-right: 12px;
    }
}

.backdrop-root {
    background-color: #d0d5da !important;
    opacity: 0.5 !important;
}

.paper-root {
    width: 424px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}
