@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 16px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
    height: 36px;
}

.title {
    height: 36px;
    line-height: 36px;
    font-family: 'inter500';
    font-size: 24px;

    @include ellipsis;
}

.last-updated-title {
    font-family: 'inter500';
    font-size: 12px;
    margin-top: 18px;
}

.last-updated-value {
    font-size: 12px;
    margin-top: 8px;
}

.divider {
    @include divider(88px);
}

.switch {
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'inter500';
    margin-top: 6px;
    line-height: 24px;

    >span {
        margin: 0 8px;
    }
}

.big-label {
    margin-top: 24px;
    font-size: 20px;
    line-height: 30px;
    font-family: 'inter500';
}

.small-label {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'inter500';
}

.property-value {
    font-size: 14px;
    margin-top: 8px;
}

.owner-name {
    color: #6e767e; // TODO add const (need to discuss with design - we have colors naming conflict in abstract)

    @include small-text-label;
    @include ellipsis;
}

.data-row {
    margin-top: 4px;
    word-break: break-all;
    font-size: 14px;
    min-height: 21px;
    line-height: 21px;
}

.link-container {
    @include ellipsis;

    a {
        font-family: 'inter500';
        color: $link;
    }
}

.next-run-time-block {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

.next-run {
    display: inline-flex;
    color: #6e767e; // TODO add const (need to discuss with design - we have colors naming conflict in abstract)
    @include small-text-label;
}

.paused-label {
    @include inactive-state-label();
    margin-left: 16px;
    width: 56px;
}

.skeleton-block {
    margin-top: 40px;
}

.skeleton-item {
    margin-top: 8px;
}

.label {
    display: inline-block;
    height: 28px;
    margin-top: 10px;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;

    &.draft {
        background-color: $neutral-200;
    }

    &.archived {
        background-color: $warning-lighter;
    }
}

.schedule-controls-block {
    user-select: none;
    margin-top: 16px;

    div:not(:last-child) {
        margin-right: 12px;
    }

    .schedule-controls-message {
        line-height: 38px;
        font-size: 14px;
    }

    button {
        color: $dark !important;

        .run-icon {
            font-size: 12px;
            margin: 4px 7px 1px 0;
            color: $neutral-600;
        }

        &[disabled] {
            opacity: 0.9;
        }

        &:hover {
            color: #fff !important;

            .run-icon {
                color: #fff;
            }
        }

        &[disabled]:hover {
            color: $dark !important;
            background-color: transparent !important;
        }
    }
}

.buttons-container > *:not(:last-child) {
    margin-right: 12px;
}

$trigger-name-width: 430px;
$trigger-type-width: 250px;
$expand-icon-width: 38px;

.triggers-table-container {
    margin: 14px 0 16px 0;

    .row {
        width: $trigger-name-width + $trigger-type-width;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid $neutral-300;
        font-size: 14px;

        &.header {
            font-family: 'inter700';
            color: $accent-color-dark;
        }

        .column-name {
            width: $trigger-name-width;
        }

        .column-type {
            width: $trigger-type-width;
        }

        &.expandable {
            font-family: 'inter400';
            width: $trigger-name-width + $trigger-type-width - $expand-icon-width;
            border-bottom: none;

            .column-name {
                width: $trigger-name-width - $expand-icon-width;
            }
        }
    }

    .trigger-summary {
        width: $trigger-name-width + $trigger-type-width;
        min-height: 40px !important;
        height: 40px !important;
        border-bottom: 1px solid $neutral-300 !important;
        padding-right: 12px !important;

        &[aria-expanded='true'] {
            border: none !important;
        }
    }

    .trigger-details {
        width: $trigger-name-width + $trigger-type-width;
        border: 1px solid $neutral-300;
        border-radius: 2px;
        padding: 8px 12px !important;
        margin: 0 !important;
        flex-direction: column;

        .trigger-label {
            font-family: 'inter500';
            margin-bottom: 8px;
        }
    }
}

.full-dataset-icon {
    margin-left: 12px;
    font-size: 16px;
    color: rgba(101, 111, 123, 0.98);
}

.used-in-report-label {
    height: 22px;
    line-height: 15px;
    padding: 4px 8px 3px 8px;
    margin: 7px 4px 0 4px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 10px;
    text-transform: uppercase;
    background-color: $warning-lighter;
    white-space: nowrap;
}
