@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 16px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
    height: 36px;
}

.controls-container > div:not(:empty):last-child {
    margin-left: 48px;
}

.title {
    height: 36px;
    line-height: 36px;
    font-family: 'inter500';
    font-size: 24px;
    text-transform: uppercase;

    @include ellipsis;
}

.owner-name {
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    color: $accent-color-dark;

    @include ellipsis;
}

.schema {
    font-family: 'inter500';
    font-size: 20px;
    margin-top: 24px;
}

.schema-download {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin-top: 8px;
    padding-right: 16px;
    border-radius: 2px;
    background-color: #f6f8fa;
    max-width: 100%;

    svg {
        color: $accent-color-dark;
        margin: 0 10px 0 18px;
    }

    span {
        font-family: 'inter500';
        font-size: 14px;
        color: $highlight-color-dark;
        cursor: pointer;
    }
}

.schema-container {
    margin: 14px 0 16px 0;

    .schema-row {
        width: 475px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid $neutral-300;
        font-size: 14px;

        &:first-child {
            font-family: 'inter700';
            color: $accent-color-dark;
        }

        .column-name {
            width: 257px;
            padding-right: 4px;
        }

        .column-type {
            width: 93px;
        }

        .column-nullable {
            width: 125px;

            .label {
                margin-left: 20px;
                font-size: 16px;
                color: rgba($text-color-alternate, 0.98);

                svg {
                    display: block;
                }
            }
        }
    }
}

.deduplicate-columns-container {
    margin: 14px 0 16px 0;

    .deduplicate-column {
        width: 350px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid $neutral-300;
        font-size: 14px;

        &:first-child {
            font-family: 'inter700';
            color: $accent-color-dark;
        }

        .column-name {
            width: 257px;

            @include ellipsis;
        }

        .column-order {
            width: 93px;
        }
    }
}

.deduplicate-icon {
    margin-left: 12px;
    font-size: 16px;
    color: rgba(101, 111, 123, 0.98);
}

.deduplicate-hint {
    font-size: 14px;
    margin-top: 4px;
}

.secondary-title {
    font-family: 'inter500';
    font-size: 16px;
    margin-top: 24px;
    line-height: 24px;
}

.property-value {
    margin-top: 4px;
    font-size: 14px;
    min-height: 21px;
    line-height: 21px;
}

.collectors {
    font-family: 'inter500';
    font-size: 20px;
    margin-top: 40px;
}

.collectors-container .secondary-title:first-child {
    margin-top: 8px;
}

.last-updated-title {
    font-family: 'inter500';
    font-size: 12px;
    margin-top: 18px;
}

.last-updated-value {
    font-size: 12px;
    margin-top: 8px;
}

.divider {
    @include divider(88px);
}

.skeleton-block {
    margin-top: 40px;
}

.skeleton-item {
    margin-top: 8px;
}

.label {
    display: inline-block;
    height: 28px;
    margin-top: 8px;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;

    &.draft,
    &.superseded,
    &.cancelled {
        background-color: $neutral-200;
    }

    &.validating,
    &.pending {
        background-color: $warning-lighter;
    }

    &.validation-failed {
        background-color: $negative-lighter;
    }

    &.promoting {
        background-color: $lighter;
    }
}

.test-state-label-container + .label {
    margin-top: 16px;
}

.additional-version-label {
    display: inline-block;
    margin: 24px 0 5px 0;
    height: 53px;
    line-height: 53px;
    border-radius: 2px;
    font-size: 14px;

    @include box-shadow;

    &.draft,
    &.superseded,
    &.cancelled {
        background-color: $neutral-200;
    }

    &.validating,
    &.pending {
        background-color: $warning-lighter;
    }

    &.validation-failed {
        background-color: $negative-lighter;
    }

    .first-message {
        font-family: 'inter500';
        margin-left: 16px;
    }

    .second-message {
        margin-left: 8px;
    }

    .link {
        font-family: 'inter500';
        color: $highlight-color-dark;
        cursor: pointer;
        margin: 0 24px;
    }
}

.breadcrumbs-additional-name {
    max-width: 100px;
    display: inline-block;
    vertical-align: top;
}

.label-button {
    font-family: 'inter500';
    margin-left: 12px;
    font-size: 14px;
    color: $highlight-color-dark;
    cursor: pointer;
}

.configuration-details-row {
    word-break: break-all;
}

.pending-date {
    margin-left: 12px;
    font-size: 14px;
    color: $dark;
    vertical-align: middle;
}

.test-state-label {
    display: inline-block;
    margin-top: 12px;
    padding: 8px 16px;
    border-radius: 2px;
    background-color: $neutral-200;
    font-size: 14px;

    .search-tooltip {
        margin-left: 8px;
    }
}

.json-value {
    white-space: pre-wrap;
    background-color: $app-background-color;
    margin-top: 8px;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
}
