@import "assets/styles/theme/theme";
.menu-anchor {
    font-family: 'inter500';
    margin: 18px 32px 0 0;
    line-height: 24px;
    cursor: pointer;
    float: right;
}

.menu-name {
    vertical-align: top;
}

.icon-open {
    transform: rotate(180deg);
}

.item {
    font-family: 'inter500' !important;

    &.focused-item {
        background-color: inherit !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
        }
    }

    &.selected-item {
        background-color: rgba(0, 0, 0, 0.08) !important;
    }
}

.paper {
    margin-top: 8px !important;
    border-radius: 2px !important;
}
