@import "assets/styles/theme/theme";
.buttons-container > *:not(:last-child) {
    margin-right: 12px;
}

.paper {
    margin-top: 5px !important;
}

.item {
    font-size: 14px !important;

    &.negative {
        color: $error-text-color;
    }

    &.focused-item {
        background-color: inherit !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
        }
    }
}
