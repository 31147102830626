@import "assets/styles/theme/theme";
.body {
    width: 584px;
}

.paragraph {
    font-size: 14px;
    line-height: 21px;
    margin: 8px 0;
}

.links-container {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid $neutral-300;
    border-radius: 2px;
    padding: 8px 0 8px 32px;
    font-size: 14px;

    .link {
        color: $link;
        font-family: 'inter500';
    }
}

.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;

        &:first-child {
            margin-right: 12px;
        }
    }
}
