@import "assets/styles/theme/theme";
.menu {
    border: 1px solid #a2a9b1;
    border-radius: 4px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    min-width: 120px;
    padding-left: 16px;
    cursor: pointer;
    user-select: none;

    &.disabled {
        border-color: #edf0f2;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #a2a9b1 !important;
        color: white;
    }
}

.icon {
    position: absolute;
    font-size: 18px !important;
    margin: 8px 0 0 6px;
}

.icon-open {
    transform: rotate(180deg);
}

.paper {
    margin-top: 5px !important;
}

.item {
    height: 37px;
    padding-left: 7px !important;

    &.focused-item {
        background-color: inherit !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
        }
    }
}
