@import "assets/styles/theme/theme";
.user-icon {
    margin: 18px 32px 0 0;
    background-color: $muted;
    color: $text-color;
    height: 24px;
    width: 24px;
    border-radius: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
    float: right;
    text-transform: uppercase;
}

.item {
    font-family: 'inter500' !important;

    &.focused-item {
        background-color: inherit !important;
    }
}

.paper {
    margin-top: 14px !important;
    border-radius: 2px !important;
}
