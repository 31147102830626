@import "assets/styles/theme/theme";
.new-source-button {
    line-height: 36px;

    .icon {
        margin-top: 6px;
        margin-right: -6px;
    }

    .icon-open {
        transform: rotate(180deg);
    }
}

.item {
    font-size: 14px !important;
    font-family: 'inter500' !important;

    &.focused-item {
        background-color: inherit !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
        }
    }
}

.paper {
    margin-top: 5px !important;
}

.search-tooltip {
    margin-left: 8px;
}
