@import "assets/styles/theme/theme";
.title {
    font-family: 'inter500';
    margin: 20px 0 16px 8px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.table-title {
    font-family: 'inter500';
    margin: 32px 0 8px 8px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.item {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    padding-left: 8px;
    cursor: pointer;
    justify-content: space-between;
    user-select: none;

    &.selected {
        color: $highlight-color-dark;
        background-color: $lighter;

        // TODO below there are colors from design, but they differ from similar already existing elements in the app
        // color: #005fbe;
        // background-color: #cee6fd;

        border-radius: 2px;

        .item-icon,
        .item-arrow-icon {
            color: $highlight-color-dark;
            // color: #005fbe;
        }

        &.disabled {
            color: $dark-muted;
            background-color: $muted;

            .item-icon,
            .item-arrow-icon {
                color: $dark-muted;
            }
        }
    }

    &.disabled {
        cursor: default;
    }

    &:last-child {
        margin-bottom: 16px;
    }

    .item-name {
        overflow: hidden;
    }

    .item-icon {
        width: 18px;
        height: 18px;
        margin: 9px 8px 0 0;
        flex-shrink: 0;
    }

    .item-arrow-icon {
        padding-right: 16px;
        font-size: 12px;
        position: relative;
        top: 12px;
        color: #a2a9b1;
    }
}

.property-name,
.property-value {
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    margin: 0 0 0 8px;
}

.property-name {
    font-family: 'inter500';
    color: #6a737c;
    margin-bottom: 4px;
}

.property-value {
    margin-bottom: 8px;
}

.no-data-message {
    font-size: 14px;
    margin: 12px 8px;
    height: 21px;
    line-height: 21px;
    color: #6a737c;
}

.spinner {
    top: 200px;
    left: calc(50% - 24px);
}
