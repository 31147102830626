@import "assets/styles/theme/theme";
.tab-root {
    font-family: 'inter500' !important;
    font-size: 16px !important;
    text-transform: none !important;
    background-color: #fff !important;
    min-height: 52px !important;
    clip-path: inset(-2px 0 0 -2px);
    width: 160px !important;
    color: $text-color !important;

    @include box-shadow;

    &:not(.tab-selected) {
        border: 1px solid $neutral-100;
        opacity: 0.7 !important;
    }

    &:first-child {
        border-top-left-radius: 4px;

    }

    &:last-child {
        border-top-right-radius: 4px;
    }
}

.tabs-root {
    overflow: visible !important;
}

.tabs-scroller {
    overflow: visible !important;
}

.tabs-indicator {
    display: none;
}


.card {
    @include card;

    padding: 8px 24px 48px 24px;
    border-top-left-radius: 0;
}
