@import "assets/styles/theme/theme";
.field:not(:last-child) {
    margin-bottom: 24px;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.big-label {
    margin-top: 40px;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.add-button-container {
    &.with-items {
        margin-top: 24px;
    }

    > button {
        min-width: 140px;
    }
}

.error {
    margin-top: 24px !important;
}
