@import "assets/styles/theme/theme";
.card {
    border-radius: 4px;
    padding: 24px;
    width: 584px;

    .paragraph {
        font-size: 14px;
        line-height: 21px;
        margin: 8px 0;
    }
}

.title {
    font-family: 'inter500';
    font-size: 20px;
    height: 30px;
    line-height: 30px;
}

.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;

        &:first-child {
            margin-right: 12px;
        }
    }
}
