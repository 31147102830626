@import "assets/styles/theme/theme";
.breadcrumbs {
    @include small-text-label;
    @include ellipsis;

    font-family: 'inter500';
    margin-top: 7px;
}

.breadcrumbs-divider {
    margin: 0 8px;
}

.breadcrumbs-link {
    color: $highlight-color-dark;
    cursor: pointer;

    &.breadcrumbs-selected {
        color: $accent-color-dark;
        pointer-events: none;
    }
}
