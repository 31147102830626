@import "assets/styles/theme/theme";
tr:hover .name {
    color: $highlight-color-dark;
}

.name-container {
    white-space: nowrap;

    .name {
        line-height: 29px;
    }
}

.label {
    display: inline-block;
    height: 28px;
    margin-left: 16px;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;

    &.draft {
        background-color: $neutral-200;
    }

    &.archived,
    &.reporting {
        background-color: $warning-lighter;
    }
}
