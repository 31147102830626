@import "assets/styles/theme/theme";
.main-container {
    min-width: $content-min-width;
}

.content-container {
    min-width: $content-min-width;
    max-width: $content-max-width;
    flex: 1 1 auto;
    padding: 32px;
    margin: 0 auto;
}
