@import "assets/styles/theme/theme";
.root {
    font-family: 'inter500' !important;
    font-size: 16px !important;
    color: $neutral-100 !important;
}

.select {
    padding-right: 31px !important;
}

.paper {
    margin-top: 10px !important;
    border-radius: 2px !important;
}

@media (max-width: 1250px) {
    .select {
        max-width: 175px;
    }
}

@media (max-width: 1200px) {
    .select {
        max-width: 90px;
    }
}

@media (max-width: 1080px) {
    .select {
        max-width: 72px;
    }
}

.underline {
    &::before, &::after {
        content: none !important;
    }
}

.icon {
    color: $neutral-100 !important;
}

.item {
    font-family: 'inter500' !important;

    &.selected {
        background-color: rgba(0, 0, 0, 0.08) !important;
    }
}
