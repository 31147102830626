@import "assets/styles/theme/theme";
.label {
    font-family: inter500;
    font-size: 14px;
    margin-bottom: 8px;
}

.switch-container {
    margin-bottom: 16px;
}

.option {
    width: 100px;
}

.field-message {
    font-size: 12px;
    margin-top: 8px;
    height: 15px;
}

.text-input input {
    width: 440px;
}
