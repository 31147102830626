@import "assets/styles/theme/theme";
.container {
    margin-bottom: 70px;

    .title {
        font-family: 'inter500';
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        margin-bottom: 24px;
    }

    .description {
        color: #6a737c;
        font-size: 14px;
        margin-bottom: 30px;
        text-align: justify;

        a {
            color: $highlight-color-dark;
            text-decoration: underline;
            white-space: nowrap;
        }
    }

    .errors-container {
        font-size: 12px;
        color: $error-text-color;

        .error {
            margin-top: 6px;

            .error-icon {
                margin-right: 4px;
            }
        }
    }

    .upload-success-message {
        margin-top: 6px;
        font-size: 12px;
        color: #28a745;
    }
}
