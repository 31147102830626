@import "assets/styles/theme/theme";
.add-column-button-container {
    margin-top: 24px;
}

.table-label {
    @include small-text-label;

    display: inline-block;
    font-family: 'inter500';
    margin: 8px 0;

    &:first-child {
        width: 332px;
    }
}

.hint {
    font-size: 14px;
    margin: 4px 0 12px 0;
}
