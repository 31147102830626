@import "assets/styles/theme/theme";
.container:not(:first-child) {
    margin-top: 24px;
}

.order-select-container {
    margin-left: 12px;
}

.delete-icon-container, .drag-icon-container {
    line-height: 36px;
    margin-left: 20px;
}

.delete-icon, .drag-icon {
    color: rgba(101, 111, 123, 0.98);
}

.delete-icon {
    cursor: pointer;
}

.drag-icon {
    cursor: move;
}
