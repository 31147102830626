@import "assets/styles/theme/theme";
.plus-icon {
    color: $neutral-600;
    font-size: 16px;
    position: relative;
    top: 10px;
    margin-left: 8px;
    padding-bottom: 4px;

    &.disabled {
        color: $muted;
    }
}

.chip-root {
    border-radius: 2px !important;
    background-color: $neutral-100 !important;
    margin: 6px 8px 0 0 !important;
    text-transform: uppercase;
}

.chip-disabled {
    background-color: #dedede !important;
}

.delete-icon {
    color: $text-color !important;
    font-size: 12px !important;
    margin-left: -2px !important;
    margin-right: 8px !important;

    &:hover {
        color: $error-text-color !important;
    }
}
