@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 16px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
    height: 36px;
}

.title {
    height: 36px;
    line-height: 36px;
    font-family: 'inter500';
    font-size: 24px;
    overflow: hidden;
}

.last-updated-title {
    font-family: 'inter500';
    font-size: 12px;
    margin-top: 18px;
}

.last-updated-value {
    font-size: 12px;
    margin-top: 8px;
}

.divider {
    @include divider(88px);
}

.switch {
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'inter500';
    margin-top: 6px;
    line-height: 24px;

    >span {
        margin: 0 8px;
    }
}

.big-label {
    margin-top: 40px;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.small-label {
    font-size: 16px;
    line-height: 24px;
    font-family: 'inter500';
}

.credential-link {
    margin-top: 4px;

    @include small-text-label;
    @include ellipsis;
}

.credential-link a,
.small-label a,
.targets-container a {
    font-family: 'inter500';
    color: $link;
}

.owner-name {
    color: #6e767e; // TODO add const (need to discuss with design - we have colors naming conflict in abstract)

    @include small-text-label;
    @include ellipsis;
}

.collector-name {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'inter500';

    @include ellipsis;
}

.configuration-details-row {
    margin-top: 4px;
    font-size: 14px;
    min-height: 21px;
    line-height: 21px;
    word-break: break-all;
}

.schedules-container {
    flex-wrap: wrap;

    .schedule-container {
        $margin: 16px;

        border: 1px solid $muted;
        border-radius: 4px;
        padding: 12px;
        margin: $margin $margin 0 0;
        max-width: calc(33.33% - $margin);

        .schedule {
            margin-top: 4px;
            font-size: 14px;
            line-height: 21px;
        }

        .runs {
            margin-top: 8px;

            .last-run, .next-run {
                font-size: 14px;
                line-height: 21px;
            }
        }

        .report-range, .collection-offset-days {
            margin: 4px 0 24px 0;
            @include small-text-label;
        }
    }
}

.breadcrumbs-additional-name {
    max-width: 100px;
    display: inline-block;
    vertical-align: top;

    @include ellipsis;
}

.pending-state-label {
    display: inline-block;
    margin-top: 30px;
    height: 53px;
    line-height: 53px;
    border-radius: 2px;
    font-size: 14px;
    background-color: $blue-100;

    @include box-shadow;

    .message {
        font-family: 'inter500';
        margin-left: 16px;
    }

    .link {
        font-family: 'inter500';
        color: $highlight-color-dark;
        cursor: pointer;
        margin: 0 24px;
    }
}

.skeleton-block {
    margin-top: 40px;
}

.skeleton-item {
    margin-top: 8px;
}

.used-in-report-label {
    height: 22px;
    line-height: 15px;
    padding: 4px 8px 3px 8px;
    margin: 7px 4px 0 4px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 10px;
    text-transform: uppercase;
    background-color: $warning-lighter;
    white-space: nowrap;
}

.label {
    display: inline-block;
    line-height: 15px;
    margin-top: 10px;
    padding: 4px 8px 3px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 10px;
    text-transform: uppercase;

    &.pending {
        border: 1px solid $blue-300;
        background-color: $blue-100;
    }

    &.superseded {
        background-color: $warning-lighter;
    }

    &.cancelled,
    &.test {
        background-color: $neutral-200;
    }

    &.validation-failed {
        background-color: $negative-lighter;
    }
}

.schedule-controls-block {
    margin-top: 16px;
    user-select: none;

    div:not(:last-child) {
        margin-right: 11px;
    }

    button {
        color: $dark !important;

        .run-icon {
            font-size: 12px;
            margin: 4px 7px 1px 0;
            color: $neutral-600;
        }

        &[disabled] {
            opacity: 0.9;
        }

        &:hover {
            color: #fff !important;

            .run-icon {
                color: #fff;
            }
        }

        &[disabled]:hover {
            color: $dark !important;
            background-color: transparent;
        }
    }
}

.controls-container > div:not(:last-child) {
    margin-right: 48px;
}

.buttons-container div:not(:last-child) {
    margin-right: 12px;
}

.label-message {
    margin-left: 12px;
    font-size: 14px;
    color: $dark;
    vertical-align: middle;
}

.targets-label {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'inter500';
}

.targets-container {
    flex-wrap: wrap;

    .target-container {
        border: 1px solid $muted;
        border-radius: 4px;
        padding: 12px;
        margin: 16px 16px 0 0;
        max-width: 100%;
    }
}
