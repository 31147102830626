@import "assets/styles/theme/theme";
.table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 14px;
}

.no-data {
    text-align: center;
    padding-top: 48px;
}

.checkbox-cell {
    padding: 0 !important;
    width: 40px;
}
