@import "assets/styles/theme/theme";
.delete-icon-container {
    line-height: 36px;

    .delete-icon {
        font-size: 16px;
        color: rgba(101, 111, 123, 0.98);
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .delete-icon {
            color: $muted;
        }
    }
}

tr:hover .name {
    color: $highlight-color-dark;
}
