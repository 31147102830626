@import "assets/styles/theme/theme";
.snackbar-container-root {
    // changed position for the case when anchorOrigin is set to { top, center }
    // next line should be removed if anchorOrigin will be changed
    top: 60px !important;

    .snackbar-root > div {
        font-family: 'inter400';
        font-size: 14px;
        max-height: 250px;
        overflow: auto;
    }
}
