@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 20px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
    height: 36px;
}

.title {
    font-family: 'inter500';
    font-size: 24px;

    @include ellipsis;
}

.owner-name {
    font-size: 20px;
    color: $accent-color-dark;

    @include ellipsis;
}

.collector {
    font-family: 'inter500';
    font-size: 20px;
    margin-top: 24px;
}

.collector-type {
    margin-top: 8px;
    font-size: 20px;
    color: $accent-color-dark;

    @include ellipsis;
}

.property-name {
    font-family: 'inter500';
    font-size: 16px;
    margin-top: 24px;
}

.property-value {
    word-break: break-all; // for cases when value contains long string without spaces
    font-size: 14px;
    margin-top: 8px;
}

.last-updated-title {
    font-family: 'inter500';
    font-size: 12px;
    margin-top: 18px;
}

.last-updated-value {
    font-size: 12px;
    margin-top: 8px;
}

.divider {
    @include divider(88px);
}
