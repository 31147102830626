@import "assets/styles/theme/theme";
.navigation {
    position: relative;
}

.pointer {
    background-color: $app-background-color;
    position: absolute;
    height: 4px;
    margin-top: -4px;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .35s;
    transition-property: left,width
}

.tab {
    display: inline-block;
    font-family: 'inter700';
    font-size: 16px;
    color: $neutral-100;
    line-height: $header-menu-height;
    margin: 0 24px;
    white-space: nowrap;
    cursor: pointer;
}

@media (max-width: 1250px) {
    .tab {
        margin: 0 12px;
    }
}

@media (max-width: 1160px) {
    .tab {
        margin: 0 7px;
    }
}

@media (max-width: 1080px) {
    .tab {
        margin: 0 5px;
    }
}

.menu-name {
    vertical-align: top;
}

.icon {
    margin-top: 18px;
}

.icon-open {
    transform: rotate(180deg);
}

.item {
    font-family: 'inter500' !important;

    &.focused-item {
        background-color: inherit !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
        }
    }

    &.selected-item {
        background-color: rgba(0, 0, 0, 0.08) !important;
    }
}

.paper {
    border-radius: 2px !important;
}
