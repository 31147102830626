@import "assets/styles/theme/theme";
.dropzone {
    height: 131px;
    border: 1px dashed #868e96;
    border-radius: 4px;
    text-align: center;
    padding-top: 27px;

    &.drag-active {
        border: 1px dashed $highlight-color !important;

        .icon {
            color: $highlight-color;
        }
    }

    &.upload-in-progress {
        border: 1px solid $highlight-color;

        .upload-link {
            cursor: default !important;
        }
    }

    &.error {
        border: 1px solid $error-border-color;
    }

    .icon {
        padding-bottom: 16px;
        color: #6a737c;
        font-size: 20px;
    }

    .upload-text {
        font-size: 14px;

        .upload-link {
            color: $highlight-color-dark;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .upload-in-progress {
        color: $highlight-color-dark;
    }

    .additional-text {
        color: #6a737c;
        font-size: 10px;
        padding-top: 8px;
        text-transform: uppercase;
    }
}
