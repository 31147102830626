@import "assets/styles/theme/theme";
.table-row-controls-container {
    white-space: nowrap;

    .details-button {
        cursor: pointer;
        user-select: none;

        &.disabled {
            cursor: default;
            color: $muted;

            .icon {
                color: $muted;
            }
        }

        &:hover:not(.disabled) {
            color: $highlight-color;

            .icon {
                color: $highlight-color;
            }
        }

        .icon {
            color: rgba(101, 111, 123, 0.98);
            margin-right: 10px;
        }
    }
}

.tooltip {
    font-size: 12px !important;
    background-color: $neutral-900 !important;
    padding: 8px !important;
    max-width: 500px !important;
}

.label {
    display: inline-block;
    height: 28px;
    padding: 6px 8px 4px 8px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;

    &.generating, &.running {
        border: 1px solid $blue-300;
        background-color: $blue-100;
    }

    &.active, &.succeeded {
        background-color: $green-100;
        color: $green-600;
    }

    &.failed {
        background-color: $negative-lighter;
    }
}

.rerun-icon {
    margin: 8px 0 0 16px;
    color: $neutral-600;

    &:hover:not(.disabled) {
        color: $highlight-color;
    }
}
