@import "assets/styles/theme/theme";
.menu-container {
    min-width: $content-min-width;
    height: $header-menu-height;
    justify-content: space-between;
    background-color: $neutral-900;
    font-family: 'inter700';
    color: $neutral-100;
}

.user-icon {
    margin: 18px 32px 0 0;
    background-color: $muted;
    color: $text-color;
    height: 24px;
    width: 24px;
    border-radius: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
}

.logo {
    margin-left: 32px;
    line-height: $header-menu-height;
    vertical-align: center;
    font-size: 20px;
}
