@import "assets/styles/theme/theme";
@import '~@2sixty/universal-ui-kit/styles/gdt-uuikit';
@import '~@2sixty/universal-ui-kit/styles/gdt-uuikit-utilities';

@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/idea';

@import 'common';
@import 'overrides';

body {
    margin: 0;
    font-family: 'inter400', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $app-background-color;
    color: $text-color;
}
