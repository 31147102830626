@import "assets/styles/theme/theme";
.upload-types,
.uploads,
.select-type {
    @include card;

    background-color: #fff;
    margin: 8px 24px 0 0;

    &:last-child {
        margin-right: 0;
    }
}

.upload-types {
    flex: 0 0 182px;
    padding: 0 16px;
}

.uploads {
    flex: 1 1 auto;
    padding: 20px 56px 48px 56px;
}

.select-type {
    flex: 1 1 auto;
    text-align: center;
    height: 500px;
    line-height: 500px;
    color: #6a737c;
}
