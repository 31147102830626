@import "assets/styles/theme/theme";
.title-container {
    height: 25px;
    justify-content: space-between;
}

.validate-button {
    margin-top: -20px;
}

.label {
    font-family: 'inter500';
    font-size: 14px;
    margin-bottom: 8px;
}

.message {
    font-size: 12px;
    margin-top: 8px;
    min-height: 15px;

    overflow-x: hidden;
    text-overflow: ellipsis;

    a {
        color: $link;
    }

    .error {
        white-space: pre-line;
    }
}

.editor > div { // override some codemirror styles
    font-size: 14px;
    border: 1px solid $muted;
    border-radius: 4px;
    height: auto;
    min-height: 103px;
    padding: 6px 8px;

    &:hover {
        background-color: $highlight-color-light;
        cursor: text;
    }

    &:focus, &:focus-within, &:active {
        border-color: $highlight-color;
    }
}

.editor.disabled > div {
    background-color: $disabled-input-color;
    cursor: pointer;
}

.editor.error > div {
    background-color: $error-background-color;
    border-color: $error-border-color;
}

.transformation-query-tooltip {
    margin-left: 6px;
}
