@import "assets/styles/theme/theme";
.submitting:not(.secondary) button {
    background-color: $highlight-color-dark !important;
}

.submitting-icon {
    margin-top: 5px;
    margin-left: -1px;
}

.secondary {
    &.submitting button .submitting-icon {
        fill: #e4e6e9;
    }

    &.submitting button:hover .submitting-icon {
        fill: #fff;
    }
}
