@import "assets/styles/theme/theme";
.field {
    margin-bottom: 24px;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}
