@import "assets/styles/theme/theme";
.body {
    width: 400px;
}

.group-select {
    margin-top: 24px;
}

.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;

        &:first-child {
            margin-right: 12px;
        }
    }
}
