@import "assets/styles/theme/theme";
.title {
    margin-top: 24px;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.item {
    margin-top: 4px;

    a {
        color: $link;
    }
}

.button {
    width: 100px;
    margin: 8px 0 0 0 !important;
    background-color: $lighter !important;
    color: $highlight-color !important;
}
