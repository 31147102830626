@import "assets/styles/theme/theme";
.field:not(:last-child) {
    margin-bottom: 24px;
}

.field-label {
    font-family: 'inter500';
    font-size: 14px;
    margin-bottom: 8px;
}

.field-message {
    font-size: 12px;
    margin-top: 8px;

    a {
        color: $link;
    }
}

.text-input input {
    width: 440px;
}

