@import "assets/styles/theme/theme";
.title {
    font-family: 'inter500';
    margin: 20px 0 8px 8px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.item {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    padding-left: 8px;
    cursor: pointer;
    justify-content: space-between;
    user-select: none;

    &.selected {
        color: $highlight-color-dark;
        background-color: $lighter;
        border-radius: 2px;

        .item-icon {
            color: $highlight-color-dark;
        }

        &.disabled {
            color: $dark-muted;
            background-color: $muted;

            .item-icon {
                color: $dark-muted;
            }
        }
    }

    &.disabled {
        cursor: default;
    }

    &:last-child {
        margin-bottom: 16px;
    }

    .item-icon {
        padding-right: 16px;
        font-size: 12px;
        position: relative;
        top: 12px;
        color: #a2a9b1;
    }
}
