@import "assets/styles/theme/theme";
.container {
    margin-top: 24px;

    &:first-child {
        margin: 0;
    }
}

.trigger-item-root {
    margin-left: 12px !important;
}

.trigger-delete-icon-container {
    line-height: 36px;
    margin-left: 20px;
}

.trigger-delete-icon {
    color: rgba(101, 111, 123, 0.98);
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.override-checkbox-container {
    margin-left: 3px;
}

.error {
    white-space: pre-line;
}
