@import "assets/styles/theme/theme";
.paper {
    border-radius: 4px;
    padding: 24px;
}

.title-root {
    font-family: 'inter500' !important;
    line-height: 30px !important;
    padding: 0 !important;
}

.content-root {
    padding: 0 !important;
    overflow-y: hidden !important;
}
