@import "assets/styles/theme/theme";
.container {
    border: 1px solid $muted;
    border-radius: 4px;
    min-height: 42px;
    font-size: 14px;
    padding: 0 8px 8px 12px;
    justify-content: space-between;
    cursor: pointer;

    &.error {
        background-color: $error-background-color;
        border-color: $error-border-color;
    }

    &.disabled {
        cursor: default;
        background-color: $disabled-input-color;
        border-color: $muted;

        .arrow {
            color: $neutral-600;
        }
    }

    &:hover:not(.error):not(.disabled) {
        background-color: $highlight-color-light;
    }
}

.arrow {
    color: #b2b7bd;
    font-size: 16px;
    top: 12px;
    position: relative;
}

.chip-root {
    border-radius: 2px !important;
    background-color: $neutral-100 !important;
    margin: 8px 8px 0 0 !important;
    text-transform: uppercase;
}

.chip-disabled {
    background-color: #dedede !important;
}

.placeholder {
    display: inline-block;
    margin-top: 12px;
}

.delete-icon {
    color: $text-color !important;
    font-size: 12px !important;
    margin-left: -2px !important;
    margin-right: 8px !important;

    &:hover {
        color: $error-text-color !important;
    }
}
