@import "assets/styles/theme/theme";
.buttons-container {
    margin-top: 32px;
    justify-content: flex-end;
    align-items: center;

    div {
        height: 36px;

        &:first-child {
            margin-right: 12px;
        }
    }
}

.text {
    font-size: 14px;
    line-height: 21px;
    margin: 8px 0;
}

.field {
    margin: 24px 20px 0 0;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.option {
    padding: 0 10px;
}

.loading {
    font-size: 14px;
}

.configuration-container {
    margin-top: 10px;
}

.warning {
    font-size: 12px;
    margin-top: 8px;
}

.offset-days-input input {
    width: 320px !important;
}
