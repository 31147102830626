@import "assets/styles/theme/theme";
.tooltip {
    font-size: 12px !important;
    background-color: $neutral-900 !important;
    padding: 8px !important;
    max-width: 500px !important;
}

.tooltip-icon {
    color: $neutral-600;
    cursor: pointer;
}
