@mixin box-shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

@mixin card {
    background-color: #fff;
    border-radius: 2px;

    @include box-shadow;
}

@mixin divider($width) {
    height: 2px;
    width: $width;
    background-color: rgba(101, 111, 123, 0.98);
    margin-top: 40px;
}

@mixin material-ui-select-root {
    color: $text-color !important;
    border: 1px solid $muted !important;
    border-radius: 4px !important;
    font-family: 'inter400' !important;
    font-size: 14px !important;
    padding: 8px 32px 8px 12px !important;
    width: 274px !important;
    height: 18px !important;
}

@mixin small-text-label {
    font-size: 14px;
    height: 21px;
    line-height: 21px;
}

@mixin inactive-state-label($font-size: 10px) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: 'inter500';
    font-size: $font-size;
    background-color: rgb(89, 96, 103);
    color: rgb(215, 218, 222);
    border-radius: 2px;
    border: 1px solid rgba(160, 165, 171, 0);
    height: 24px;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
