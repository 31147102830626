@import "assets/styles/theme/theme";
.accordion-root {
    box-shadow: none !important;
    margin: 0 !important;

    &:before {
        content: none !important;
    }
}

.accordion-summary-root {
    font-family: 'inter500';
    font-size: 16px;
    justify-content: normal !important;
    padding: 0 !important;
    min-height: 48px !important;
}

.accordion-summary-content {
    flex-grow: unset !important;
    margin: 0 !important;
}

.expand-icon {
    padding: 12px;

    svg {
        margin-top: -1px;
    }
}

.accordion-details-root {
    display: block !important;
    padding: 0 !important;
    margin-bottom: 12px !important;
}
