@import "assets/styles/theme/theme";
.basic-info .column {
    margin: 20px 24px 0 0;
}

.property-name {
    color: #6a737c;
    font-size: 14px;
    height: 21px;
    line-height: 21px;
}

.property-value {
    font-size: 18px;
    height: 27px;
    line-height: 27px;
}

.main-info {
    margin-top: 24px;
}

.partition-info {
    margin-top: 40px;
}

.main-info,
.partition-info,
.snapshot-info {
    .property-name {
        font-family: 'inter500';
        color: #242c34;
        width: 120px;
        min-width: 120px;
    }

    .property-name,
    .property-value {
        font-size: 12px;
        height: 18px;
        line-height: 18px;
        overflow: hidden;
    }
}

.query {
    margin-top: 24px;
    font-size: 12px;

    span {
        font-family: 'inter500';
    }
}
