@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 16px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
}

.title {
    font-family: 'inter500';
    font-size: 24px;
    padding-bottom: 24px;
}

.buttons-container > *:not(:last-child) {
    margin-right: 12px;
}

.field {
    margin-bottom: 24px;
}

.label {
    font-size: 14px;
    padding-bottom: 8px;
    font-family: 'inter500';
}

.warning {
    font-size: 12px;
    margin-top: 8px;
}

.global-error {
    color: $error-text-color;
    font-size: 12px;
    margin-bottom: 20px;
}

.big-label {
    margin-top: 40px;
    font-size: 20px;
    padding-bottom: 16px;
}

.additional-label {
    margin-left: 8px;
    color: $accent-color-dark;
    font-family: 'inter400';
}

.collector-title {
    font-family: 'inter500';
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;

    @include ellipsis;
}

.go-live-radio {
    width: 80px;
}

.go-live-radio-warning {
    color: $accent-color-dark;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    margin-top: 14px;
}

.non-editable-field {
    @include small-text-label;
    @include ellipsis;
}

.dialog-bold-text {
    font-family: 'inter700';
}

.dialog-warning-text {
    margin-top: 8px;
    font-family: 'inter500';
    color: $error-text-color;
}

.go-live-warnings {
    .go-live-warning {
        @include small-text-label;
    }

    .go-live-strong-warning {
        @include small-text-label;

        color: $error-text-color;
    }

    div {
        margin-top: 4px;
    }

    div:first-child {
        margin-top: 8px;
    }
}

.test-state-label {
    display: inline-block;
    margin: 8px 0 20px 0;
    padding: 8px 16px;
    border-radius: 2px;
    background-color: $neutral-200;
    font-size: 14px;

    .search-tooltip {
        margin-left: 8px;
    }
}

.label-tooltip {
    margin-left: 5px;
}
