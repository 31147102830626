@import "assets/styles/theme/theme";
.card {
    @include card;

    margin-top: 20px;
    padding: 24px 24px 48px 24px;
}

.title-container {
    justify-content: space-between;
}

.title {
    font-family: 'inter500';
    font-size: 24px;
    padding-bottom: 24px;
}

.buttons-container > *:not(:last-child) {
    margin-right: 12px;
}
