@import "assets/styles/theme/theme";
.name {
    line-height: 28px;
}

.label {
    display: inline-block;
    height: 28px;
    padding: 6px 8px 4px 8px;
    margin-left: 16px;
    border-radius: 2px;
    font-family: 'inter500';
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: $green-100;
    color: $green-600;
}

.controls-container {
    float: right;
}

.authorization-link-container {
    cursor: pointer;
    padding: 9px 20px 0 0;

    &:hover:not(.disabled) {
        color: $highlight-color;
    }

    &.disabled {
        color: $muted;
    }

    .link-icon {
        margin-right: 8px;
    }
}

.delete-icon-container {
    line-height: 36px;

    .delete-icon {
        font-size: 16px;
        color: rgba(101, 111, 123, 0.98);
        cursor: pointer;

        &:hover {
            color: $highlight-color;
        }
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .delete-icon {
            color: $muted;
        }
    }
}
