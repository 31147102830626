@import "assets/styles/theme/theme";
.label {
    font-size: 20px;
    line-height: 30px;
}

.small-label {
    line-height: 21px;
    margin: 8px 0 6px 0;
}

.warning {
    display: inline-block;
    padding: 6px 8px 4px 8px;
    font-size: 12px;
    margin-bottom: 8px;
    background-color: $warning-lighter;
}

.add-credential-button {
    font-size: 14px;
    height: 48px;
    line-height: 21px;
    padding: 15px 0 0 22px;
    cursor: pointer;

    .plus-icon {
        margin-right: 10px;
        color: $neutral-600;
        font-size: 14px;
    }

    &:hover {
        color: $highlight-color;

        .plus-icon {
            color: $highlight-color;
        }
    }
}
