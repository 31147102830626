$text-color: #2b3238;
$text-color-alternate: #656f7b;
$app-background-color: #eef2f5;
$accent-color-dark: #616d7b;
$highlight-color-light: #f3fafc;
$highlight-color: #029cbb;
$highlight-color-dark: #027e97;
$disabled-input-color: #fafafa;

$error-text-color: #a63054;
$error-background-color: #fbf4f6;
$error-border-color: #c85578;

$muted: #d3d6d9;
$dark-muted: #838f9c;
$light-muted: #b6bdc5;
$dark: #363e45;
$negative-lighter: #f4e1e7;
$warning-lighter: #fcefd9;
$neutral-100: #f4f5f5;
$neutral-200: #f0f3f4;
$neutral-300: #e7e8e9;
$neutral-600: #8a939e;
$neutral-900: #212931;
$lighter: #d5f1f6;
$blue-100: #f3f6ff;
$blue-300: #b8d1ff;
$link: #005cb7;
$green-600: #05b978ff;
$green-100: #f0fcf5ff;
