@import "assets/styles/theme/theme";
.title {
    font-family: 'inter700';
    font-size: 24px;
    padding-bottom: 26px;
}

.table-controls {
    justify-content: space-between;
    height: 36px;
    margin-bottom: 16px;

    .filter-button {
        background-color: #fff !important;
        color: $text-color !important;
        border: 1px solid #d0d5da !important;
        box-shadow: none !important;
        margin-right: 12px;

        &[disabled] {
            opacity: 0.5;
        }

        .filter-icon {
            margin-right: 8px;
        }
    }
}

.card {
    @include card;

    padding: 8px 24px 48px 24px;
}
