@import "assets/styles/theme/theme";
.checkbox-root {
    margin-bottom: 1px !important;

    &:hover {
        background-color: transparent !important;
    }
}

.checkbox-root:hover .checkbox-unchecked-icon,
.checkbox-disabled .checkbox-unchecked-icon {
    border-color: $highlight-color !important;
    color: $highlight-color-light !important;
}

.checkbox-checked {
    margin-bottom: 0 !important;
}

.checkbox-unchecked-icon {
    color: white;
    width: 19px !important;
    height: 18px;
    border: 1px solid $muted;
    border-radius: 4px;
}

.checkbox-checked-icon {
    width: 21px !important;
    height: 21px;
}

.checkbox-label-root {
    display: inline !important;
    margin: 0 !important;
}

.checkbox-label {
    font-size: 14px !important;
    cursor: pointer;
    padding-left: 1px;
    user-select: none;
}

.checkbox-label-disabled {
    cursor: default;
    color: $neutral-600 !important;
}
