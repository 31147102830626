@import "assets/styles/theme/theme";
.menu-container {
    justify-content: space-between;
    min-width: $content-min-width;
    background-color: $neutral-900;
    height: $header-menu-height;
    font-family: 'inter700';
    color: $neutral-100;
    user-select: none;
}

.start-section, .end-section {
    width: 210px;
}

.client-select-container {
    padding-left: 16px;
    padding-top: 14px;
}

.client-name {
    font-family: 'inter500';
    padding-left: 16px;
    padding-top: 20px;
    white-space: nowrap;
}
